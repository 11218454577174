import React, { useState, useContext } from "react";
import {
  Container,
  FormGroup,
  Label,
  Input,
  Button,
  Form,
  Card,
  CardBody,
  FormText
} from "reactstrap";
import { useForm } from "react-hook-form";
import Axios from "axios";
import Config from "../config/config";
import Auth from "../helpers/Auth";
import { Redirect } from "react-router-dom";
import logo from "../assets/images/logo.png";
import AppContext from "../context/AppContext";

const Login = props => {
  const [redirect, setRedirect] = useState(false);

  const { setModal } = useContext(AppContext);

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {}
  });

  const onSubmit = data => {
    const { username, password } = data;

    Axios.post(Config.baseUrl + "/login", {
      username: username,
      password: password
    })
      .then(async data => {
        await Auth.login(data.data);
        setRedirect(true);
      })
      .catch(error => {
        const message_code = error.response.data.message;

        let message = "";
        if (message_code === "user_not_found") {
          message = "User not found!";
        }

        setModal({
          show: true,
          type: "alert",
          title: "Failed",
          body: message
        });
      });
  };

  return (
    <>
      <div className="login-container mx-auto">
        <Card>
          <CardBody>
            <Container fluid>
              <p>
                <img className="img-fluid" alt="Logo Cinta Bahasa" src={logo} />
              </p>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <Label>Username</Label>
                  <Input
                    name="username"
                    innerRef={register({ required: true })}
                  />
                  {errors.username && (
                    <FormText color="danger">Username is required!</FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label> Password </Label>
                  <Input
                    type="password"
                    name="password"
                    innerRef={register({ required: true })}
                  />
                  {errors.password && (
                    <FormText color="danger">Password is required!</FormText>
                  )}
                </FormGroup>
                <Button> Login </Button>
              </Form>
            </Container>
          </CardBody>
        </Card>
      </div>

      {redirect && (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location }
          }}
        />
      )}
    </>
  );
};

export default Login;
