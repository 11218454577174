import React, { useReducer, useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import FilterMonth from "./filters/FilterMonth";
import FilterYear from "./filters/FilterYear";
import FilterDate from "./filters/FilterDate";
import { months3dig } from "../config/const";
import moment from "moment";

const now = new Date();

const nowMonth = moment(now).format("M");
const nowYear = moment(now).format("Y");

const startDate = moment([nowYear, nowMonth - 1]).toDate();

const endDate = moment(now)
  .endOf("month")
  .toDate();

const startYear = 2018;

const formValueReducer = (state, action) => {
  return { ...state, ...action };
};

const formValueInitialState = {};

const DashboardFilter = props => {
  const { onChange, formFilter, applyToAll: applyToAllProp } = props;

  useEffect(() => {
    if (formFilter) {
      const { type, formData, title } = formFilter;
      setFilterType(type);
      setFilterData({ [type]: { title, formData } });
    }
  }, [formFilter]);

  const [applyToAll, setApplyToAll] = useState(false);

  useEffect(() => {
    setApplyToAll(applyToAllProp);
  }, [applyToAllProp]);

  const [showModal, setShowModal] = useState(false);

  const [filterType, setFilterType] = useState("");

  const [filterData, setFilterData] = useReducer(
    formValueReducer,
    formValueInitialState
  );

  const toggle = () => {
    setShowModal(!showModal);
  };

  return (
    <div>
      <div className="d-flex align-items-center btn-filter-container">
        <Button size="sm" color="danger" onClick={toggle}>
          Filter
        </Button>
      </div>
      <Modal isOpen={showModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Filter Register Date</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Input
              type="select"
              value={filterType}
              onChange={e => {
                setFilterType(e.target.value);
              }}
            >
              <option value="">-- all datas --</option>
              <option value="by-month">By month range</option>
              <option value="by-year">By year range</option>
              <option value="by-date">By date range</option>
            </Input>
          </FormGroup>

          {filterType === "by-month" && (
            <FilterMonth
              startYear={startYear}
              endYear={nowYear}
              formData={
                filterData["by-month"]
                  ? filterData["by-month"].formData
                  : {
                      monthFrom: 1,
                      monthTo: Number(nowMonth),
                      year: Number(nowYear)
                    }
              }
              onChange={formData => {
                let title =
                  "from <strong>" +
                  months3dig[formData.monthFrom - 1] +
                  "</strong> to <strong>" +
                  months3dig[formData.monthTo - 1] +
                  "</strong> in <strong>" +
                  formData.year +
                  "</strong>";

                if (formData.monthFrom === formData.monthTo) {
                  title =
                    "on <strong>" +
                    months3dig[formData.monthFrom] +
                    "</strong> in <strong>" +
                    formData.year +
                    "</strong>";
                }

                setFilterData({
                  "by-month": {
                    title: title,
                    formData
                  }
                });
              }}
            />
          )}

          {filterType === "by-year" && (
            <FilterYear
              startYear={startYear}
              endYear={nowYear}
              formData={
                filterData["by-year"]
                  ? filterData["by-year"].formData
                  : { yearFrom: nowYear, yearTo: nowYear }
              }
              onChange={formData => {
                let title =
                  "from <strong>" +
                  formData.yearFrom +
                  "</strong> to <strong>" +
                  formData.yearTo +
                  "</strong>";
                if (formData.yearFrom === formData.yearTo) {
                  title = "in <strong>" + formData.yearFrom + "</strong>";
                }

                setFilterData({ "by-year": { title: title, formData } });
              }}
            />
          )}

          {filterType === "by-date" && (
            <FilterDate
              formData={
                filterData["by-date"]
                  ? filterData["by-date"].formData
                  : { startDate, endDate }
              }
              onChange={formData => {
                let title =
                  "from <strong>" +
                  moment(formData[0]).format("DD/MM/YYYY") +
                  "</strong> to <strong>" +
                  moment(formData[1]).format("DD/MM/YYYY") +
                  "</strong>";

                const startDate = moment(formData[0]).format("YYYY-MM-DD");
                const endDate = moment(formData[1]).format("YYYY-MM-DD");

                setFilterData({
                  "by-date": {
                    title: title,
                    formData: { startDate, endDate }
                  }
                });
              }}
            />
          )}

          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                checked={applyToAll}
                onChange={e => {
                  setApplyToAll(e.target.checked);
                }}
              />{" "}
              Apply filter to all
            </Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              const selectedFilter = filterType ? filterData[filterType] : "";

              const { title, formData } = selectedFilter;

              onChange({
                type: filterType,
                title,
                formData: formData,
                applyToAll
              });

              setShowModal(false);
            }}
          >
            Filter
          </Button>{" "}
          <Button color="link" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

DashboardFilter.defaultProps = {
  applyToAll: false
};

export default DashboardFilter;
