import React, { useState, useEffect } from "react";
import {
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormGroup,
  Label,
  Form
} from "reactstrap";
import Axios from "axios";
import Config from "../config/config";
import { useForm, Controller } from "react-hook-form";
import { WithContext as ReactTags } from "react-tag-input";

const KeyCodes = {
  comma: 188,
  enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const StudentFilter = props => {
  const { formValue, onSubmit: onSubmitFilter } = props;

  const [showModal, setShowModal] = useState(false);

  const [dataCountries, setDataCountries] = useState();

  const [dataCourses, setDataCourses] = useState();

  const [dataLocations, setDataLocations] = useState();

  const [tags, setTags] = useState([]);

  const [tagSuggestions, setTagSuggestions] = useState([]);

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    control
  } = useForm({
    defaultValues: { gender: "", paymentReceived: "", paymentRequestSent: "" }
  });

  const toggle = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    loadDataCourses();
    loadDataLocation();
    loadDataCountry();
    loadDataTags();
  }, []);

  useEffect(() => {
    let tags = [];

    if (formValue.tags) {
      if (Array.isArray(formValue.tags)) {
        tags = formValue.tags.map(tag => {
          return { id: tag, text: tag };
        });
      } else {
        tags = [{ id: formValue.tags, text: formValue.tags }];
      }
    }

    setTags(tags);

    reset({ ...getValues(), ...formValue, tags });
  }, [formValue]);

  const loadDataCourses = async () => {
    const results = await Axios.get(Config.baseUrl + "/term/course");
    setDataCourses(results.data);
  };

  const loadDataLocation = async () => {
    const results = await Axios.get(Config.baseUrl + "/term/location");
    setDataLocations(results.data);
  };

  const loadDataCountry = async () => {
    const results = await Axios.get(Config.baseUrl + "/country");
    setDataCountries(results.data);
  };

  const loadDataTags = async () => {
    const results = await Axios.get(Config.baseUrl + "/term/tag");

    setTagSuggestions(
      results.data.map(data => {
        return {
          id: data.name,
          text: data.name
        };
      })
    );
  };

  const onSubmit = data => {
    if (data.tags) {
      data.tags = data.tags.map(tag => {
        return tag.text;
      });
    }

    onSubmitFilter(data);
    toggle();
  };

  return (
    <div>
      <Button size="sm" color="secondary" onClick={toggle}>
        Filter
      </Button>
      <Modal isOpen={showModal} toggle={toggle}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={toggle}>Filter</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>First Name or Last Name or Email</Label>
              <Input name="keyword" innerRef={register} />
            </FormGroup>
            <FormGroup>
              <Label>Country</Label>
              <Input type="select" name="country" innerRef={register}>
                <option value="">Select country</option>
                {dataCountries &&
                  dataCountries.map((country, index) => {
                    return (
                      <option key={"country-" + index} value={country.id}>
                        {country.country_name}
                      </option>
                    );
                  })}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>Course Type</Label>
              <Input type="select" name="courseType" innerRef={register}>
                <option value="">Select course type</option>
                {dataCourses &&
                  dataCourses.map((course, index) => {
                    return (
                      <option key={"course-" + index} value={course.id}>
                        {course.name}
                      </option>
                    );
                  })}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>Select course location</Label>
              <Input type="select" name="courseLocation" innerRef={register}>
                <option value="">Course location</option>
                {dataLocations &&
                  dataLocations.map((location, index) => {
                    return (
                      <option key={"location-" + index} value={location.id}>
                        {location.name}
                      </option>
                    );
                  })}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>Gender</Label>
              <div>
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="radio"
                      name="gender"
                      value=""
                      innerRef={register}
                    />{" "}
                    All
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="radio"
                      name="gender"
                      value="Male"
                      innerRef={register}
                    />{" "}
                    Male
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="radio"
                      name="gender"
                      value="Female"
                      innerRef={register}
                    />{" "}
                    Female
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="radio"
                      name="gender"
                      value="Prefer not to say"
                      innerRef={register}
                    />{" "}
                    Prefer not to say
                  </Label>
                </FormGroup>
              </div>
            </FormGroup>

            <FormGroup tag="fieldset">
              <Label>Payment Request Sent</Label>
              <div>
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="radio"
                      name="paymentRequestSent"
                      value=""
                      innerRef={register}
                    />{" "}
                    All
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="radio"
                      name="paymentRequestSent"
                      value="1"
                      innerRef={register}
                    />{" "}
                    Sent
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="radio"
                      name="paymentRequestSent"
                      value="0"
                      innerRef={register}
                    />{" "}
                    Not Sent
                  </Label>
                </FormGroup>
              </div>
            </FormGroup>

            <FormGroup tag="fieldset">
              <Label>Payment Received</Label>
              <div>
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="radio"
                      name="paymentReceived"
                      value=""
                      innerRef={register}
                    />{" "}
                    All
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="radio"
                      name="paymentReceived"
                      value="Yes"
                      innerRef={register}
                    />{" "}
                    Yes
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="radio"
                      name="paymentReceived"
                      value="No"
                      innerRef={register}
                    />{" "}
                    No
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="radio"
                      name="paymentReceived"
                      value="Pending"
                      innerRef={register}
                    />{" "}
                    Pending
                  </Label>
                </FormGroup>
              </div>
            </FormGroup>

            <FormGroup>
              <Label>Tags</Label>
              <Controller
                as={
                  <ReactTags
                    placeholder="Type a tag"
                    tags={tags}
                    suggestions={tagSuggestions}
                    handleDelete={i => {
                      const nextTags = tags.filter((tag, index) => index !== i);
                      setTags(nextTags);
                      setValue("tags", nextTags);
                    }}
                    handleDrag={() => {}}
                    handleAddition={data => {
                      const nextTags = [...tags, data];
                      setTags(nextTags);
                      setValue("tags", nextTags);
                    }}
                    delimiters={delimiters}
                  />
                }
                name="tags"
                control={control}
                defaultValue={[]}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Filter
            </Button>{" "}
            <Button color="link" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default StudentFilter;
