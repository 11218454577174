import React, { useState, useEffect } from "react";

import DateRangePicker from "@wojtekmaj/react-daterange-picker";

const FilterDate = props => {
  const { formData, onChange } = props;

  const [dates, setDates] = useState([]);

  useEffect(() => {
    if (formData && formData.startDate && formData.endDate) {
      const returnDate = [
        new Date(formData.startDate),
        new Date(formData.endDate)
      ];
      setDates(returnDate);
      onChange(returnDate);
    }
  }, []);

  return (
    <DateRangePicker
      format="d/MM/y"
      onChange={dates => {
        onChange(dates);
        setDates(dates);
      }}
      value={dates}
    />
  );
};

export default FilterDate;
