import Config from "../config/config";

const Auth = {
  login: async data => {
    await localStorage.setItem(Config.authString, JSON.stringify(data));
  },

  authData: () => {
    const authString = localStorage.getItem(Config.authString);
    const authData = JSON.parse(authString);
    return authData;
  },

  logout: () => {
    localStorage.removeItem(Config.authString);
  }
};

export default Auth;
