import React, { useEffect, useState, useContext } from "react";
import { Chart } from "react-google-charts";
import Axios from "axios";
import DashboardFilter from "../DashboardFilter";
import Config from "../../config/config";
import CircleLoader from "../CircleLoader";
import Auth from "../../helpers/Auth";
import DashboardContext from "../../context/DashboardContext";
import stripHtml from "string-strip-html";

const DashWidgetReferrer = () => {
  const { dispatchFilterActions, filterAll } = useContext(DashboardContext);

  const [datas, setDatas] = useState();

  const [filterTitle, setFilterTitle] = useState("All");

  const [formFilter, setFormFilter] = useState();

  const [applyToAll, setApplyToAll] = useState(false);

  const loadData = async (params, title = "All") => {
    const authData = await Auth.authData();
    const results = await Axios.get(Config.baseUrl + "/dashboard/referrer", {
      headers: {
        Authorization: "Bearer " + authData.token
      },
      params
    });

    const dataReferrers = results.data;

    const referrers = dataReferrers.map(referrer => {
      return [referrer.referrer, referrer.total];
    });

    setDatas([["Referrer", title], ...referrers]);
  };

  useEffect(() => {
    loadData();

    dispatchFilterActions({
      loadData,
      setFilterTitle,
      setFormFilter,
      setApplyToAll
    });
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between dashboard-widget mb-3">
        <div>
          <h3>Student Referral</h3>
          <span className="filter-label">
            Filter:{" "}
            <span dangerouslySetInnerHTML={{ __html: filterTitle }}></span>
          </span>
        </div>
        <DashboardFilter
          applyToAll={applyToAll}
          formFilter={formFilter}
          onChange={filterData => {
            const { type, formData, title, applyToAll } = filterData;

            setApplyToAll(applyToAll);

            if (applyToAll) {
              filterAll(type, formData, title);
            } else {
              loadData(formData, stripHtml(title));
              setFilterTitle(title);
            }
          }}
        />
      </div>
      <Chart
        height={"300px"}
        chartType="BarChart"
        loader={<CircleLoader />}
        data={datas}
        options={{
          chartArea: { width: "70%" },
          hAxis: {
            titlePosition: "none",
            minValue: 0
          },
          gridlines: { count: -1 },
          vAxis: { format: "0" },
          legend: { position: "none" }
        }}
      />
    </>
  );
};

export default DashWidgetReferrer;
