import React, { useEffect, useState } from "react";
import { Row, Col, Input, FormGroup, Label } from "reactstrap";

const FilterYear = props => {
  const { startYear, endYear, formData, onChange } = props;
  const { yearFrom, yearTo } = formData;

  const [years, setYears] = useState([]);

  useEffect(() => {
    onChange(formData);

    let years = [];
    for (let iYear = startYear; iYear <= endYear; iYear++) {
      years.push(Number(iYear));
    }

    setYears(years);
  }, []);

  return (
    <Row>
      <Col>
        <FormGroup>
          <Label>Year from</Label>
          <Input
            type="select"
            value={yearFrom}
            onChange={e => {
              const value = Number(e.target.value);
              let newFormValue = {};
              if (value > yearTo) {
                newFormValue.yearTo = value;
              }
              newFormValue.yearFrom = value;

              onChange({ ...formData, ...newFormValue });
            }}
          >
            {years.map((year, index) => {
              return (
                <option value={Number(year)} key={"year-from-" + index}>
                  {year}
                </option>
              );
            })}
          </Input>
        </FormGroup>
      </Col>
      <Col>
        <Label>Year to</Label>
        <Input
          type="select"
          value={yearTo}
          onChange={e => {
            const value = Number(e.target.value);
            let newFormValue = {};
            if (value < yearFrom) {
              newFormValue.yearFrom = value;
            }
            newFormValue.yearTo = value;

            props.onChange({ ...formData, ...newFormValue });
          }}
        >
          {years.map((year, index) => {
            return (
              <option value={Number(year)} key={"year-to-" + index}>
                {year}
              </option>
            );
          })}
        </Input>
      </Col>
    </Row>
  );
};

export default FilterYear;
