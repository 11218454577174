import React, { useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Student from "./pages/Student";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import ProtectedRoute from "./helpers/ProtectedRoute";
import ChangePassword from "./pages/ChangePassword";
import EditStudent from "./pages/EditStudent";
import { Modal, ModalBody, ModalFooter, Button, ModalHeader } from "reactstrap";
import { AppContextProvider } from "./context/AppContext";
import LoggedInRoute from "./helpers/LoggedInRoute";

const App = () => {
  const [modal, setModal] = useState({
    show: false,
    type: "alert"
  });

  const toggleModal = () => {
    setModal(!modal);
  };

  return (
    <AppContextProvider value={{ modal, setModal }}>
      <BrowserRouter>
        <Switch>
          <ProtectedRoute exact path="/" component={Dashboard} />
          <ProtectedRoute exact path="/student" component={Student} />
          <ProtectedRoute
            exact
            path="/student/:id/edit"
            component={EditStudent}
          />
          <ProtectedRoute
            exact
            path="/change-password"
            component={ChangePassword}
          />
          <LoggedInRoute exact path="/login" component={Login} />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>

      <Modal isOpen={modal.show} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{modal.title}</ModalHeader>
        <ModalBody>{modal.body}</ModalBody>
        <ModalFooter>
          {modal.type === "confirmation" && (
            <Button
              color="primary"
              onClick={() => {
                modal.onConfirm();
                toggleModal();
              }}
            >
              Confirm
            </Button>
          )}
          <Button color="link" onClick={toggleModal}>
            {modal.type === "confirmation" && <span>Cancel</span>}
            {modal.type === "alert" && <span>Ok</span>}
          </Button>
        </ModalFooter>
      </Modal>
    </AppContextProvider>
  );
};

export default App;
