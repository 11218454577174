import React, { useState, useContext } from "react";
import "../App.scss";
import classnames from "classnames";
import { Nav, NavItem } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import logo from "../assets/images/logo.png";
import AppContext from "../context/AppContext";
import Auth from "../helpers/Auth";

export const withLayout = WrappedComponent => {
  const Component = props => {
    const [openSidebar, setOpenSidebar] = useState(false);

    const { modal, setModal } = useContext(AppContext);

    const [redirectToLogin, setRedirectToLogin] = useState(false);

    const toggleSidebar = () => {
      setOpenSidebar(!openSidebar);
    };

    return (
      <>
        {redirectToLogin && (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )}

        <div id="layout" className="d-flex h-100">
          <div
            id="left"
            className={classnames({
              open: openSidebar,
              closed: !openSidebar
            })}
          >
            <div className="icon" onClick={toggleSidebar}>
              &equiv;
            </div>
            <span className="logo">
              <img src={logo} />
            </span>
            <div
              className={classnames(
                "sidebar",
                "h-100",
                "d-flex",
                "flex-column",
                "justify-content-between",
                {
                  open: openSidebar,
                  closed: !openSidebar
                }
              )}
            >
              <div className="header">&nbsp;</div>
              <div className="content py-3 d-flex flex-column justify-content-between">
                <Nav vertical>
                  <NavItem>
                    <Link to="/" className="nav-link">
                      Dashboard
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link to="/student" className="nav-link">
                      Student
                    </Link>
                  </NavItem>
                </Nav>

                <Nav vertical>
                  <NavItem>
                    <Link to="/change-password" className="nav-link">
                      Change Password
                    </Link>
                  </NavItem>
                  <NavItem>
                    <a
                      href="#"
                      className="nav-link"
                      onClick={e => {
                        e.preventDefault();

                        setModal({
                          ...modal,
                          type: "confirmation",
                          body: "Are you sure want to logout?",
                          title: "Logout",
                          onConfirm: async () => {
                            await Auth.logout();
                            setRedirectToLogin(true);
                          },
                          show: true
                        });
                      }}
                    >
                      Logout
                    </a>
                  </NavItem>
                </Nav>
              </div>
            </div>
          </div>
          <div id="main" className="d-flex flex-column">
            <div className="header">&nbsp;</div>
            <div className="content overflow-auto">
              <div className="py-5">
                <WrappedComponent {...props} />
              </div>
            </div>
            <footer className="text-center py-3">
              <p className="mb-0">Cinta Bahasa Administration.</p>
            </footer>
          </div>
        </div>
      </>
    );
  };

  return Component;
};
