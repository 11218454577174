import React, { useContext, useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import Axios from "axios";
import DashboardFilter from "../DashboardFilter";
import CountUp from "react-countup";
import Config from "../../config/config";
import Auth from "../../helpers/Auth";
import DashboardContext from "../../context/DashboardContext";

const DashWidgetCourseTotal = () => {
  const { dispatchFilterActions, filterAll } = useContext(DashboardContext);

  const [total, setTotal] = useState(0);

  const [filterTitle, setFilterTitle] = useState("All");

  const [formFilter, setFormFilter] = useState();

  const [applyToAll, setApplyToAll] = useState(false);

  const loadData = async params => {
    const authData = await Auth.authData();
    const results = await Axios.get(Config.baseUrl + "/dashboard/total", {
      headers: {
        Authorization: "Bearer " + authData.token
      },
      params
    });

    setTotal(results.data.total);
  };

  useEffect(() => {
    loadData();

    dispatchFilterActions({
      loadData,
      setFilterTitle,
      setFormFilter,
      setApplyToAll
    });
  }, []);

  return (
    <div className="dashboard-widget widget-course-total d-flex flex-column h-100">
      <div className="d-flex justify-content-between mb-3">
        <div>
          <h3>Total Registration</h3>
          <span className="filter-label">
            Filter:{" "}
            <span dangerouslySetInnerHTML={{ __html: filterTitle }}></span>
          </span>
        </div>
        <DashboardFilter
          applyToAll={applyToAll}
          formFilter={formFilter}
          onChange={filterData => {
            const { type, formData, title, applyToAll } = filterData;

            setApplyToAll(applyToAll);

            if (applyToAll) {
              filterAll(type, formData, title);
            } else {
              loadData(formData);
              setFilterTitle(title);
            }
          }}
        />
      </div>
      <CountUp
        className="counter flex-grow-1 d-flex justify-content-center align-items-center"
        end={total}
      />
    </div>
  );
};

export default DashWidgetCourseTotal;
