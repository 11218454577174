import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";
import DashboardFilter from "../DashboardFilter";
import { Table } from "reactstrap";
import Config from "../../config/config";
import Auth from "../../helpers/Auth";
import { Link } from "react-router-dom";
import DashboardContext from "../../context/DashboardContext";
import moment from "moment";
import iconPencil from "../../assets/images/pencil.svg";

const DashWidgetRecent = () => {
  const { dispatchFilterActions, filterAll } = useContext(DashboardContext);

  const [filterTitle, setFilterTitle] = useState("All");

  const [formFilter, setFormFilter] = useState();

  const [applyToAll, setApplyToAll] = useState(false);

  const [datas, setDatas] = useState([]);

  const loadData = async params => {
    const authData = await Auth.authData();
    const results = await Axios.get(Config.baseUrl + "/dashboard/recent", {
      headers: {
        Authorization: "Bearer " + authData.token
      },
      params
    });

    setDatas(results.data);
  };

  useEffect(() => {
    loadData();

    dispatchFilterActions({
      loadData,
      setFilterTitle,
      setFormFilter,
      setApplyToAll
    });
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between dashboard-widget mb-3">
        <div>
          <h3>Recent Registration</h3>
          <span className="filter-label">
            Filter:{" "}
            <span dangerouslySetInnerHTML={{ __html: filterTitle }}></span>
          </span>
        </div>
        <DashboardFilter
          applyToAll={applyToAll}
          formFilter={formFilter}
          onChange={filterData => {
            const { type, formData, title, applyToAll } = filterData;

            setApplyToAll(applyToAll);

            setFilterTitle(title);

            if (applyToAll) {
              filterAll(type, formData, title);
            } else {
              loadData(formData);
            }
          }}
        />
      </div>
      <Table size="sm" striped responsive={true}>
        <thead>
          <tr>
            <th>Register Date</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Selected Course</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {datas.length >= 1 &&
            datas.map((data, index) => {
              return (
                <tr key={"student-" + index}>
                  <td>
                    {data.created_at
                      ? moment(data.created_at).format("D/MM/YYYY")
                      : ""}
                  </td>
                  <td>{data.first_name}</td>
                  <td>{data.last_name}</td>
                  <td>{data.email}</td>
                  <td>{data.course}</td>
                  <td>
                    <Link
                      className="badge badge-info mr-2"
                      to={"student/" + data.id + "/edit"}
                    >
                      <span className="d-flex mr-2">
                        <img src={iconPencil} />
                        View/Edit
                      </span>
                    </Link>
                  </td>
                </tr>
              );
            })}
          {datas.length <= 0 && (
            <tr>
              <td colSpan={6}>There is no data</td>
            </tr>
          )}
        </tbody>
      </Table>
      <Link to="/student" className="btn btn-primary btn-sm">
        View All Students
      </Link>
    </>
  );
};

export default DashWidgetRecent;
