import React, { useState, useEffect } from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  FormText,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import Axios from "axios";
import Config from "../config/config";
import { useForm, Controller } from "react-hook-form";
import { WithContext as ReactTags } from "react-tag-input";
import { months, numberOfHoursTotals } from "../config/const";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const FormStudent = (props) => {
  const { datas, onSave, loading } = props;

  const [dataCourses, setDataCourses] = useState();

  const [dataLocations, setDataLocations] = useState();

  const [dataGenders, setDataGenders] = useState();

  const [dataCountries, setDataCountries] = useState();

  const [dataPhoneCodes, setDataPhoneCodes] = useState();

  const [dataReferrers, setDataReferrers] = useState();

  const [dataPaymentPreferences, setDataPaymentPreferences] = useState();

  const [startingDate, setStartingDate] = useState(new Date());

  const {
    register,
    handleSubmit,
    errors,
    getValues,
    reset,
    watch,
    setValue,
    control,
  } = useForm({
    defaultValues: {
      paymentRequestSent: (0).toString(),
      paymentReceived: "No",
      newsletter: false,
    },
  });

  const [tags, setTags] = useState([]);

  const [tagSuggestions, setTagSuggestions] = useState([]);

  useEffect(() => {
    loadDataCourses();
    loadDataLocations();
    loadDataGenders();
    loadDataCountries();
    loadDataTags();
    loadDataReferrers();
    loadDataPaymentPreferences();
  }, []);

  useEffect(() => {
    if (datas) {
      if (datas.startingDate) {
        setStartingDate(datas.startingDate);
      }

      if (datas.tags) {
        setTags(datas.tags);
      }
    }
  }, [datas]);

  useEffect(() => {
    if (
      dataCourses &&
      dataLocations &&
      dataGenders &&
      dataCountries &&
      dataPaymentPreferences &&
      dataReferrers &&
      datas
    ) {
      reset({ ...getValues(), ...datas });
    }
  }, [
    datas,
    dataCourses,
    dataLocations,
    dataGenders,
    dataCountries,
    dataPaymentPreferences,
    dataReferrers,
    getValues,
    reset
  ]);

  const loadDataCourses = async () => {
    const results = await Axios.get(Config.baseUrl + "/term/course");
    setDataCourses(results.data);
  };

  const loadDataLocations = async () => {
    const results = await Axios.get(Config.baseUrl + "/term/location");
    setDataLocations(results.data);
  };

  const loadDataGenders = async () => {
    const results = await Axios.get(Config.baseUrl + "/term/gender");
    setDataGenders(results.data);
  };

  const loadDataPaymentPreferences = async () => {
    const results = await Axios.get(
      Config.baseUrl + "/term/payment-preference"
    );
    setDataPaymentPreferences(results.data);
  };

  const loadDataReferrers = async () => {
    const results = await Axios.get(Config.baseUrl + "/term/referrer");
    setDataReferrers(results.data);
  };

  const loadDataCountries = async () => {
    const results = await Axios.get(Config.baseUrl + "/country");
    setDataCountries(results.data);

    const _phoneCodes = results.data.map((dt) => {
      return Number(dt.phone_code);
    });
    _phoneCodes.sort(function (a, b) {
      return a - b;
    });

    const _setPhoneCodes = [...new Set(_phoneCodes)];
    _setPhoneCodes.splice(_setPhoneCodes.indexOf(0), 1);

    setDataPhoneCodes(_setPhoneCodes);
  };

  const loadDataTags = async () => {
    const results = await Axios.get(Config.baseUrl + "/term/tag");

    setTagSuggestions(
      results.data.map((data) => {
        return {
          id: data.name,
          text: data.name,
        };
      })
    );
  };

  const selectedCourseId = watch("course");

  const selectedCourseObj = dataCourses
    ? dataCourses.find((course) => {
        return Number(course.id) === Number(selectedCourseId);
      })
    : "";

  const courseFlags = selectedCourseObj
    ? selectedCourseObj.flag.split(",")
    : "";

  const selectedNumberOfHoursTotal = watch("numberOfHoursTotal");

  const selectedReferrer = watch("referrer");
  let selectedReferrerObj = {};
  if (dataReferrers && selectedReferrer) {
    selectedReferrerObj = dataReferrers.find((referrer) => {
      return referrer.id === Number(selectedReferrer);
    });
  }

  return (
    <>
      <Form
        onSubmit={handleSubmit(onSave)}
        onKeyPress={(e) => {
          if (e.target.type !== "textarea" && e.which === 13 /* Enter */) {
            e.preventDefault();
          }
        }}
      >
        <FormGroup>
          <Label>
            First Name<span className="text-danger">*</span>
          </Label>
          <Input
            name="firstName"
            innerRef={register({
              required: true,
            })}
          />
          {errors.firstName && (
            <FormText color="danger">First Name is required!</FormText>
          )}
        </FormGroup>
        <FormGroup>
          <Label>
            Last Name<span className="text-danger">*</span>
          </Label>
          <Input name="lastName" innerRef={register({ required: true })} />
          {errors.lastName && (
            <FormText color="danger">Last Name is required!</FormText>
          )}
        </FormGroup>
        <FormGroup>
          <Label>
            Gender<span className="text-danger">*</span>
          </Label>
          <FormGroup tag="fieldset">
            {dataGenders &&
              dataGenders.map((gender, index) => {
                return (
                  <FormGroup check inline key={"gender-" + index}>
                    <Label check>
                      <Input
                        type="radio"
                        name="gender"
                        value={gender.id}
                        innerRef={register({ required: true })}
                      />{" "}
                      {gender.name}
                    </Label>
                  </FormGroup>
                );
              })}
          </FormGroup>
          {errors.gender && (
            <FormText color="danger">Gender is required!</FormText>
          )}
        </FormGroup>
        <FormGroup>
          <Label>
            Nationality<span className="text-danger">*</span>
          </Label>
          <Input
            type="select"
            name="country"
            innerRef={register({
              required: true,
            })}
          >
            <option value="">-- select country --</option>
            {dataCountries &&
              dataCountries.map((country, index) => {
                return (
                  <option key={"country-" + index} value={country.id}>
                    {country.country_name}
                  </option>
                );
              })}
          </Input>
          {errors.country && (
            <FormText color="danger">Country is required!</FormText>
          )}
        </FormGroup>
        <FormGroup>
          <Label>
            Where Are You Located Now?<span className="text-danger">*</span>
          </Label>
          <Input
            type="select"
            name="located_now"
            innerRef={register({
              required: true,
            })}
          >
            <option value="">-- select country --</option>
            {dataCountries &&
              dataCountries.map((country, index) => {
                return (
                  <option key={"located-now-" + index} value={country.id}>
                    {country.country_name}
                  </option>
                );
              })}
          </Input>
          {errors.located_now && (
            <FormText color="danger">Located Now is required!</FormText>
          )}
        </FormGroup>
        <FormGroup>
          <Label>
            Email<span className="text-danger">*</span>
          </Label>
          <Input
            name="email"
            innerRef={register({
              required: true,
            })}
          />
          {errors.email && (
            <FormText color="danger">Email is required!</FormText>
          )}
        </FormGroup>
        <FormGroup>
          <Label>Phone</Label>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <Input type="select" name="phoneCode" innerRef={register}>
                <option value="">-- phone code --</option>
                {dataPhoneCodes &&
                  dataPhoneCodes.map((code, index) => {
                    return (
                      <option key={"phone-code-" + index} value={code}>
                        +{code}
                      </option>
                    );
                  })}
              </Input>
            </InputGroupAddon>
            <Input placeholder="Phone" name="phone" innerRef={register} />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <Label>
            Which Course Would You Like To Take?<span className="text-danger">*</span>
          </Label>
          <Input
            name="course"
            innerRef={register({ required: true })}
            type="select"
          >
            <option value="">-- select course type --</option>
            {dataCourses &&
              dataCourses.map((course, index) => {
                return (
                  <option value={course.id} key={"course-" + index}>
                    {course.name}
                  </option>
                );
              })}
          </Input>
          {errors.course && (
            <FormText color="danger">Course Type is required!</FormText>
          )}
        </FormGroup>
        {courseFlags && courseFlags.includes("location") && (
          <FormGroup>
            <Label>
              Location<span className="text-danger">*</span>
            </Label>
            <Input
              type="select"
              name="location"
              innerRef={register({ required: true })}
            >
              <option value="">-- select location --</option>
              {dataLocations.map((location, index) => {
                return (
                  <option value={location.id} key={"location-" + index}>
                    {location.name}
                  </option>
                );
              })}
            </Input>
            {errors.location && (
              <FormText color="danger">Location is required!</FormText>
            )}
          </FormGroup>
        )}
        {courseFlags && courseFlags.includes("starting_date") && (
          <FormGroup>
            <Label>
              Starting Date<span className="text-danger">*</span>
            </Label>
            <div>
              <Controller
                as={
                  <ReactDatePicker
                    className="form-control"
                    selected={startingDate}
                  />
                }
                onChange={([date]) => {
                  setStartingDate(date);

                  return { value: date };
                }}
                rules={{ required: true }}
                name="startingDate"
                control={control}
                defaultValue={startingDate}
              />
            </div>
            {errors.startingDate && (
              <FormText color="danger">Starting Date is required!</FormText>
            )}
          </FormGroup>
        )}
        {courseFlags && courseFlags.includes("starting_month") && (
          <FormGroup>
            <Label>
              Starting Month<span className="text-danger">*</span>
            </Label>
            <Input
              type="select"
              name="startingMonth"
              innerRef={register({ required: true })}
            >
              <option value="">-- select month --</option>
              {months.map((month, index) => {
                return (
                  <option value={month} key={"starting-month-" + index}>
                    {month}
                  </option>
                );
              })}
            </Input>
            {errors.startingMonth && (
              <FormText color="danger">Starting Month is required!</FormText>
            )}
          </FormGroup>
        )}
        {courseFlags && courseFlags.includes("preferred_time") && (
          <FormGroup>
            <Label>
              Preferred Times<span className="text-danger">*</span>
            </Label>
            <Input
              type="select"
              name="preferredTime"
              innerRef={register({ required: true })}
            >
              <option value="">-- select preferred times --</option>
              <option value="Morning">Morning</option>
              <option value="Afternoon">Afternoon</option>
              <option value="Late Afternoon">Late Afternoon</option>
            </Input>
            {errors.preferredTime && (
              <FormText color="danger">Preferred Times is required!</FormText>
            )}
          </FormGroup>
        )}
        {courseFlags && courseFlags.includes("skype") && (
          <FormGroup>
            <Label>
              Skype<span className="text-danger">*</span>
            </Label>
            <Input name="skype" innerRef={register({ required: true })} />
            {errors.skype && (
              <FormText color="danger">Skype is required!</FormText>
            )}
          </FormGroup>
        )}
        {courseFlags && courseFlags.includes("number_of_hours_total") && (
          <FormGroup>
            <Label>
              Number of hours total<span className="text-danger">*</span>
            </Label>
            <Input
              name="numberOfHoursTotal"
              innerRef={register({ required: true })}
              type="select"
            >
              <option value="">-- select number of hours total --</option>
              {numberOfHoursTotals.map((number, index) => (
                <option value={number} key={"number-of-hours-total-" + index}>
                  {number}
                </option>
              ))}
              <option value="other">Other</option>
            </Input>
            {selectedNumberOfHoursTotal === "other" && (
              <Input
                className="mt-2"
                name="numberOfHoursTotalOther"
                innerRef={register({ required: true })}
              />
            )}
            {(errors.numberOfHoursTotal || errors.numberOfHoursTotalOther) && (
              <FormText color="danger">
                Number of Hours Total is required!
              </FormText>
            )}
          </FormGroup>
        )}
        {courseFlags && courseFlags.includes("number_of_hours_per_day") && (
          <FormGroup>
            <Label>
              Number of hours per day<span className="text-danger">*</span>
            </Label>
            <Input
              name="numberOfHoursPerDay"
              innerRef={register({ required: true })}
              type="select"
            >
              <option value="">-- select number of hours per day --</option>
              <option value="Regular – (2 hours per day)">
                Regular – (2 hours per day)
              </option>
              <option value="Intensive – (4 or more hours per day)">
                Intensive – (4 or more hours per day)
              </option>
              <option value="6-Month Course – (2-4 Hours per day)">
                6-Month Course – (2-4 Hours per day)
              </option>
            </Input>
            {errors.numberOfHoursPerDay && (
              <FormText color="danger">
                Number of hours per day is required!
              </FormText>
            )}
          </FormGroup>
        )}
        <FormGroup>
          <Label>Payment Request Sent?</Label>
          <FormGroup tag="fieldset">
            <FormGroup check inline>
              <Label check>
                <Input
                  type="radio"
                  name="paymentRequestSent"
                  value={1}
                  innerRef={register}
                />{" "}
                Yes
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Label check>
                <Input
                  type="radio"
                  name="paymentRequestSent"
                  value={0}
                  innerRef={register}
                />{" "}
                No
              </Label>
            </FormGroup>
          </FormGroup>
        </FormGroup>
        <FormGroup>
          <Label>Payment Received?</Label>
          <FormGroup tag="fieldset">
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="paymentReceived"
                  value="Yes"
                  innerRef={register({ required: true })}
                />{" "}
                Yes
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="paymentReceived"
                  value="No"
                  innerRef={register({ required: true })}
                />{" "}
                No
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="paymentReceived"
                  value="Pending"
                  innerRef={register({ required: true })}
                />{" "}
                Pending
              </Label>
            </FormGroup>
          </FormGroup>
        </FormGroup>
        <FormGroup>
          <Label>Payment Preference</Label>
          <Input type="select" name="paymentPreference" innerRef={register}>
            <option value="">-- select payment preference --</option>
            {dataPaymentPreferences &&
              dataPaymentPreferences.map((preference, index) => {
                return (
                  <option
                    value={preference.id}
                    key={"payment-preference-" + index}
                  >
                    {preference.name}
                  </option>
                );
              })}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label>
            How did you find out about us? Did someone refer you to us?
          </Label>
          <Input type="select" name="referrer" innerRef={register}>
            <option value="">-- select --</option>
            {dataReferrers &&
              dataReferrers.map((referrer, index) => {
                return (
                  <option value={referrer.id} key={"referrer-" + index}>
                    {referrer.name}
                  </option>
                );
              })}
          </Input>

          {selectedReferrerObj &&
            (selectedReferrerObj.flag === "other" ||
              selectedReferrerObj.flag === "person") && (
              <Input
                className="mt-2"
                name="referrerDesc"
                innerRef={register({ required: true })}
              />
            )}
          {errors.referrerDesc && (
            <FormText color="danger">Referrer is required!</FormText>
          )}
        </FormGroup>
        <FormGroup>
          <Label>Why would you like to learn with us?</Label>
          <Input type="textarea" name="reason" innerRef={register} />
        </FormGroup>
        <FormGroup>
          <Label>Newsletter</Label>
          <FormGroup check>
            <Label check>
              <Input type="checkbox" innerRef={register} name="newsletter" />{" "}
              Subscribe to newsletter
            </Label>
          </FormGroup>
        </FormGroup>
        <FormGroup>
          <Label>Question</Label>
          <Input
            type="textarea"
            readOnly
            value={datas && datas.message ? datas.message : ""}
          />
        </FormGroup>
        <FormGroup>
          <Label>Notes</Label>
          <Input type="textarea" name="note" innerRef={register} />
        </FormGroup>
        <FormGroup>
          <Label>Tags</Label>
          <Controller
            as={
              <ReactTags
                autofocus={false}
                tags={tags}
                suggestions={tagSuggestions}
                handleDelete={(i) => {
                  const nextTags = tags.filter((tag, index) => index !== i);
                  setTags(nextTags);
                  setValue("tags", nextTags);
                }}
                handleDrag={() => {}}
                handleAddition={(data) => {
                  const nextTags = [...tags, data];
                  setTags(nextTags);
                  setValue("tags", nextTags);
                }}
                delimiters={delimiters}
              />
            }
            name="tags"
            control={control}
            defaultValue={[]}
          />
        </FormGroup>
        <Button disabled={loading}>
          {!loading && <span>Save</span>}
          {loading && <span>Loading...</span>}
        </Button>
      </Form>
    </>
  );
};

export default FormStudent;
