import React, { useEffect, useState } from "react";
import { Row, Col, Input, FormGroup, Label } from "reactstrap";
import { months } from "../../config/const";

const FilterMonth = props => {
  const { startYear, endYear, formData, onChange } = props;
  const { monthFrom, monthTo, year } = formData;

  const [years, setYears] = useState([]);

  useEffect(() => {
    onChange(formData);

    let years = [];
    for (let iYear = startYear; iYear <= endYear; iYear++) {
      years.push(Number(iYear));
    }

    setYears(years);
  }, []);

  return (
    <Row>
      <Col>
        <FormGroup>
          <Label>Month from</Label>
          <Input
            type="select"
            value={monthFrom}
            onChange={e => {
              const value = Number(e.target.value);

              let newFormValue = {};
              if (value > monthTo) {
                newFormValue.monthTo = value;
              }
              newFormValue.monthFrom = value;

              onChange({
                ...formData,
                ...newFormValue
              });
            }}
          >
            {months.map((month, index) => {
              return (
                <option value={index + 1} key={"month-from-" + index}>
                  {month}
                </option>
              );
            })}
          </Input>
        </FormGroup>
      </Col>
      <Col>
        <Label>Month to</Label>
        <Input
          type="select"
          value={monthTo}
          onChange={e => {
            const value = Number(e.target.value);
            let newFormValue = {};
            if (value < monthFrom) {
              newFormValue.monthFrom = value;
            }
            newFormValue.monthTo = value;

            onChange({
              ...formData,
              ...newFormValue
            });
          }}
        >
          {months.map((month, index) => {
            return (
              <option value={index + 1} key={"month-to-" + index}>
                {month}
              </option>
            );
          })}
        </Input>
      </Col>
      <Col>
        <Label>Year</Label>
        <Input
          type="select"
          value={year}
          onChange={e => {
            onChange({
              ...formData,
              year: e.target.value
            });
          }}
        >
          {years.map((year, index) => {
            return (
              <option value={Number(year)} key={"year-" + index}>
                {year}
              </option>
            );
          })}
        </Input>
      </Col>
    </Row>
  );
};

export default FilterMonth;
