import React, { useContext, useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import Axios from "axios";
import DashboardFilter from "../DashboardFilter";
import Config from "../../config/config";
import CircleLoader from "../CircleLoader";
import Auth from "../../helpers/Auth";
import DashboardContext from "../../context/DashboardContext";
import stripHtml from "string-strip-html";

const DashWidgetGender = () => {
  const { dispatchFilterActions, filterAll } = useContext(DashboardContext);

  const [datas, setDatas] = useState();

  const [filterTitle, setFilterTitle] = useState("All");

  const [applyToAll, setApplyToAll] = useState(false);

  const [formFilter, setFormFilter] = useState();

  const loadData = async (params, title = "All") => {
    const authData = await Auth.authData();

    const results = await Axios.get(Config.baseUrl + "/dashboard/gender", {
      headers: {
        Authorization: "Bearer " + authData.token
      },
      params
    });

    const dataGenders = results.data;

    const dataLength = dataGenders.length;
    const dataTotal = dataGenders.reduce((total, item) => {
      return total + item.total;
    }, 0);

    const genders = [];
    for (let i = 0; i < dataLength; i++) {
      const dataGender = dataGenders[i];
      const { gender, total } = dataGender;
      genders.push([gender, (total / dataTotal) * 100]);
    }

    setDatas([["Gender", title], ...genders]);
  };

  useEffect(() => {
    loadData();

    dispatchFilterActions({
      loadData,
      setFilterTitle,
      setFormFilter,
      setApplyToAll
    });
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between dashboard-widget mb-3">
        <div>
          <h3>By Gender</h3>
          <span className="filter-label">
            Filter:{" "}
            <span dangerouslySetInnerHTML={{ __html: filterTitle }}></span>
          </span>
        </div>

        <DashboardFilter
          applyToAll={applyToAll}
          formFilter={formFilter}
          onChange={filterData => {
            const { type, formData, title, applyToAll } = filterData;

            setApplyToAll(applyToAll);

            if (applyToAll) {
              filterAll(type, formData, title);
            } else {
              loadData(formData, stripHtml(title));
              setFilterTitle(title);
            }
          }}
        />
      </div>
      <Chart
        height={"300px"}
        chartType="PieChart"
        loader={<CircleLoader />}
        data={datas}
        options={{
          legend: "bottom",
          chartArea: { width: 400, height: 200 }
        }}
      />
    </>
  );
};

export default DashWidgetGender;
