import React, { useState } from "react";
import {
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormGroup,
  Label,
  Form
} from "reactstrap";
import { useForm } from "react-hook-form";

const fields = [
  { name: "last_name", label: "Last Name" },
  { name: "gender", label: "Gender" },
  { name: "country", label: "Country" },
  { name: "email", label: "Email" },
  { name: "phone", label: "Phone" },
  { name: "skype", label: "Skype" },
  { name: "course", label: "Course" },
  { name: "location", label: "Course Location" },
  { name: "payment_preference", label: "Payment Preference" },
  { name: "referrer", label: "Referrer" },
  { name: "payment_request_sent", label: "Payment Request Sent" },
  { name: "payment_received", label: "Payment Received" },
  { name: "note", label: "Note" }
];

const defaultValues = {};
for (let i = 0; i < fields.length; i++) {
  const field = fields[i];

  defaultValues[field.name] = true;
}

const ExportStudentFields = props => {
  const { onSubmit: onSubmitFilter } = props;

  const [showModal, setShowModal] = useState(false);

  const { register, handleSubmit } = useForm({
    defaultValues
  });

  const toggle = () => {
    setShowModal(!showModal);
  };

  const onSubmit = data => {
    onSubmitFilter(data);
    toggle();
  };

  return (
    <div>
      <Button size="sm" color="secondary" onClick={toggle}>
        Export to excel
      </Button>
      <Modal isOpen={showModal} toggle={toggle}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={toggle}>Filter</ModalHeader>
          <ModalBody>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" checked disabled /> First Name
              </Label>
            </FormGroup>
            {fields.map((field, index) => (
              <FormGroup check key={"field-" + index}>
                <Label check>
                  <Input
                    type="checkbox"
                    name={field.name}
                    innerRef={register}
                  />{" "}
                  {field.label}
                </Label>
              </FormGroup>
            ))}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Export to excel
            </Button>{" "}
            <Button color="link" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default ExportStudentFields;
