import React, { useContext } from "react";
import { withLayout } from "../layout/Main";
import {
  Container,
  FormGroup,
  Label,
  Input,
  FormText,
  Card,
  CardBody,
  Button
} from "reactstrap";
import { useForm } from "react-hook-form";
import Axios from "axios";
import Config from "../config/config";
import Auth from "../helpers/Auth";
import AppContext from "../context/AppContext";

const ChangePassword = () => {
  const { setModal } = useContext(AppContext);

  const { register, handleSubmit, errors, watch, reset } = useForm();

  const onSubmit = async (data, e) => {
    const authData = await Auth.authData();

    Axios.post(
      Config.baseUrl + "/change-password",
      {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
        confirmPassword: data.confirmPassword
      },
      {
        headers: {
          Authorization: "Bearer " + authData.token
        }
      }
    )
      .then(() => {
        setModal({
          show: true,
          type: "alert",
          title: "Success",
          body: "You have successfully update the password."
        });
        reset();
      })
      .catch(error => {
        const message_code = error.response.data.message;
        let message = "";
        if (message_code === "wrong_password") {
          message = "The Old Password is wrong!";
        }

        setModal({
          show: true,
          type: "alert",
          title: "Failed",
          body: message
        });
      });
  };

  return (
    <Container fluid>
      <div className="form-container mx-auto">
        <Card>
          <CardBody>
            <h2 className="page-title mt-0">Change Password</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <Label>Old Password</Label>
                <Input
                  type="password"
                  name="oldPassword"
                  innerRef={register({
                    required: true
                  })}
                />

                {errors.oldPassword && (
                  <FormText color="danger">Old Password is required!</FormText>
                )}
              </FormGroup>

              <FormGroup>
                <Label>New Password</Label>
                <Input
                  type="password"
                  name="newPassword"
                  innerRef={register({
                    required: true
                  })}
                />
                {errors.newPassword && (
                  <FormText color="danger">New Password is required!</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Confirm Password</Label>
                <Input
                  type="password"
                  name="confirmPassword"
                  innerRef={register({
                    required: true,
                    validate: {
                      password: value =>
                        watch("newPassword") && value === watch("newPassword")
                    }
                  })}
                />

                {errors.confirmPassword &&
                  errors.confirmPassword.type === "required" && (
                    <FormText color="danger">
                      Confirm Password is required!
                    </FormText>
                  )}
                {errors.confirmPassword &&
                  errors.confirmPassword.type === "password" && (
                    <FormText color="danger">Password is not same!</FormText>
                  )}
              </FormGroup>
              <Button>Save</Button>
            </form>
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};

export default withLayout(ChangePassword);
