import React, { useState, useReducer } from "react";
import { withLayout } from "../layout/Main";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button
} from "reactstrap";
import DashWidgetCourseTotal from "../components/dashboard-widgets/CourseTotal";
import DashWidgetCountry from "../components/dashboard-widgets/Country";
import DashWidgetCourse from "../components/dashboard-widgets/CourseType";
import DashWidgetReferrer from "../components/dashboard-widgets/Referrer";
import DashWidgetRecent from "../components/dashboard-widgets/Recent";
import DashWidgetGender from "../components/dashboard-widgets/Gender";
import { DashboardContextProvider } from "../context/DashboardContext";
import stripHtml from "string-strip-html";

const initialStateFilterActions = [];
const reducerFilterActions = (state, action) => {
  return [...state, action];
};
const Dashboard = () => {
  const [filterActions, dispatchFilterActions] = useReducer(
    reducerFilterActions,
    initialStateFilterActions
  );

  const filterAll = async (type, formData, title) => {
    filterActions.forEach(action => {
      action.setFilterTitle(title);
      action.loadData(formData, stripHtml(title));
      action.setFormFilter({ type, formData, title });
      action.setApplyToAll(true);
    });
  };

  return (
    <Container>
      <DashboardContextProvider value={{ dispatchFilterActions, filterAll }}>
        <Card>
          <CardBody>
            <DashWidgetRecent />
          </CardBody>
        </Card>

        <Row>
          <Col sm={12} md={5} className="mt-3">
            <Card className="h-100">
              <CardBody>
                <DashWidgetCourseTotal />
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} md={7} className="mt-3">
            <Card>
              <CardBody>
                <DashWidgetGender />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <Card className="mt-3">
              <CardBody>
                <DashWidgetCourse />
              </CardBody>
            </Card>
          </Col>
          <Col sm={12}>
            <Card className="mt-3">
              <CardBody>
                <DashWidgetCountry />
              </CardBody>
            </Card>
          </Col>

          <Col sm={12}>
            <Card className="mt-3">
              <CardBody>
                <DashWidgetReferrer />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </DashboardContextProvider>
    </Container>
  );
};

export default withLayout(Dashboard);
