import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import Auth from "./Auth";
import Axios from "axios";
import Config from "../config/config";

const loginStatus = {
  LOGGEDIN: "loggedId",
  NOTLOGGEDIN: "notLoggedIn"
};

const LoggedInRoute = props => {
  const { component: PropsComp, exact, path } = props;

  const [loggedInStatus, setLoggedInStatus] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadAuthData = async () => {
      const authData = await Auth.authData();

      if (authData && authData.token) {
        setIsLoading(true);

        Axios.get(Config.baseUrl + "/me", {
          headers: {
            Authorization: "Bearer " + authData.token
          }
        }).then(
          meData => {
            setLoggedInStatus(loginStatus.LOGGEDIN);
            setIsLoading(false);
          },
          () => {
            setIsLoading(false);
            setLoggedInStatus(loginStatus.NOTLOGGEDIN);
          }
        );
      } else {
        setLoggedInStatus(loginStatus.NOTLOGGEDIN);
      }
    };

    loadAuthData();
  }, []);

  return (
    <Route
      exact={exact}
      path={path}
      render={props => {
        if (!isLoading && loggedInStatus === loginStatus.LOGGEDIN) {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location }
              }}
            />
          );
        } else if (!isLoading && loggedInStatus === loginStatus.NOTLOGGEDIN) {
          return <PropsComp />;
        } else {
          return <p>loading...</p>;
        }
      }}
    />
  );
};

export default LoggedInRoute;
