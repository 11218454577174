import React from "react";

import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

function paginationItem(c, m) {
  var current = c,
    last = m,
    delta = 2,
    left = current - delta,
    right = current + delta + 1,
    range = [],
    rangeWithDots = [],
    l;

  for (let i = 1; i <= last; i++) {
    if (i === 1 || i === last || (i >= left && i < right)) {
      range.push(i);
    }
  }

  for (let i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (i - l !== 1) {
        rangeWithDots.push("...");
      }
    }
    rangeWithDots.push(i);
    l = i;
  }

  return rangeWithDots;
}

const PaginationItems = props => {
  const newPaginationItems = [];

  const paginationItems = paginationItem(
    props.current,
    Math.floor(props.pages)
  );

  for (let i = 0; i < paginationItems.length; i++) {
    const pg = paginationItems[i];

    newPaginationItems.push(
      <React.Fragment key={"pagination-item-" + i}>
        {pg !== "..." && (
          <PaginationItem active={pg === props.current}>
            <PaginationLink
              href=""
              onClick={() => {
                props.onPageClick(pg);
              }}
            >
              {pg}
            </PaginationLink>
          </PaginationItem>
        )}
        {pg === "..." && (
          <PaginationItem>
            <span className="page-link">{pg}</span>
          </PaginationItem>
        )}
      </React.Fragment>
    );
  }

  return newPaginationItems;
};

const PaginationCmp = props => {
  return (
    <Pagination aria-label="Page navigation example">
      {/*<PaginationItem>
        <PaginationLink first href="#" />
      </PaginationItem>
      <PaginationItem>
        <PaginationLink previous href="#" />
      </PaginationItem>*/}
      <PaginationItems {...props} />
      {/*<PaginationItem>
        <PaginationLink next href="#" />
      </PaginationItem>
      <PaginationItem>
        <PaginationLink last href="#" />
      </PaginationItem>*/}
    </Pagination>
  );
};

export default PaginationCmp;
