import React, { useState, useEffect, useContext } from "react";
import { withLayout } from "../layout/Main";
import { Table, Container, Card, CardBody, Badge } from "reactstrap";
import StudentFilter from "../components/StudentFilter";
import { Link } from "react-router-dom";
import Axios from "axios";
import Config from "../config/config";
import PaginationCmp from "../components/PaginationCmp";
import queryString from "query-string";
import Auth from "../helpers/Auth";
import iconDelete from "../assets/images/delete.svg";
import iconPencil from "../assets/images/pencil.svg";
import AppContext from "../context/AppContext";
import ExportStudentFields from "../components/ExportStudentFields";
import moment from "moment";

const limit = 20;

const Student = (props) => {
  const [datas, setDatas] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [total, setTotal] = useState(0);

  const [excelLink, setExcelLink] = useState("");

  const [queryParams, setQueryParams] = useState("");

  const [exportFields, setExportFields] = useState();

  const { modal, setModal } = useContext(AppContext);

  const [currentUrl, setCurrentUrl] = useState("");

  const setHistory = (queryParams) => {
    props.history.push("/student?" + queryString.stringify(queryParams));
  };

  const loadDatas = async (queryParams) => {
    const authData = await Auth.authData();

    Axios.get(Config.baseUrl + "/student", {
      headers: {
        Authorization: "Bearer " + authData.token,
      },
      params: queryParams,
    }).then(
      (response) => {
        const {
          data: { total, students },
        } = response;

        setDatas(students);
        setTotal(total);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    const queryStrings = queryString.parse(props.location.search);

    setQueryParams(queryStrings);

    loadDatas(queryStrings);

    setCurrentPage(queryStrings.page ? Number(queryStrings.page) : 1);

    setCurrentUrl(
      encodeURIComponent(props.location.pathname + props.location.search)
    );
  }, []);

  useEffect(() => {
    const asyncSetExcelLink = async () => {
      const authData = await Auth.authData();

      const fields = [];

      //if(exportFields)
      /*Object.keys(exportFields).forEach(field => {
        if (exportFields[field] === true) {
          fields.push(field);
        }
      });*/

      //console.log("__fields", fields);

      setExcelLink(
        Config.baseUrl +
          "/student/excel?" +
          queryString.stringify({ ...queryParams, token: authData.token })
      );
    };

    asyncSetExcelLink();
  }, [queryParams, exportFields]);

  const deleteStudent = async (id) => {
    const authData = await Auth.authData();

    Axios.delete(Config.baseUrl + "/student/" + id, {
      headers: {
        Authorization: "Bearer " + authData.token,
      },
    }).then(
      (response) => {
        loadDatas(queryParams);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <Container fluid>
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <div className="d-flex flex-column flex-sm-row align-items-sm-center">
              <h2 className="page-title mt-0 mb-0">Student</h2>
              <span className="ml-sm-3">
                [<Link to="/">Back to dashboard</Link>]
              </span>
            </div>

            <div className="d-flex">
              {excelLink && (
                <>
                  {/*<a id="exportToExcel" href={excelLink} className="d-none">
                Export to excel
            </a>*/}
                  <a
                    id="exportToExcel"
                    href={excelLink}
                    className="btn btn-primary btn-sm"
                  >
                    Export to excel
                  </a>
                </>
              )}

              {/* <ExportStudentFields
              onSubmit={data => {
                setExportFields(data);
                //document.getElementById("exportToExcel").click();
                //console.log("__data", data);
              }}
            />*/}
              <div className="ml-2">
                <StudentFilter
                  formValue={queryParams}
                  onSubmit={(data) => {
                    setQueryParams({ ...queryParams, ...data });
                    setHistory({ ...queryParams, ...data });
                    loadDatas({ ...queryParams, ...data });
                  }}
                />
              </div>
            </div>
          </div>
          <Table size="sm" striped responsive={true}>
            <thead>
              <tr>
                <th>Register Date</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Selected Course</th>
                <th>Payment Request</th>
                <th>Payment Received</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {datas.map((student, index) => {
                const editUrl =
                  "/student/" + student.id + "/edit?back=" + currentUrl;
                return (
                  <tr key={"student-row-" + index}>
                    <td>
                      {student.created_at
                        ? moment(student.created_at).format("DD/MM/YYYY")
                        : ""}
                    </td>
                    <td>{student.first_name}</td>
                    <td>{student.last_name}</td>
                    <td>{student.email}</td>
                    <td>{student.course}</td>
                    <td>
                      {student.payment_request_sent === 1 ? (
                        <Badge color="success">Sent</Badge>
                      ) : (
                        <Badge color="danger">Not Sent</Badge>
                      )}
                    </td>
                    <td>
                      {student.payment_received === "Yes" && (
                        <Badge color="success">Yes</Badge>
                      )}
                      {student.payment_received === "Pending" && (
                        <Badge color="secondary">Pending</Badge>
                      )}
                      {student.payment_received === "No" && (
                        <Badge color="danger">No</Badge>
                      )}
                    </td>
                    <td>
                      <div className="d-flex">
                        <Link className="badge badge-info mr-2" to={editUrl}>
                          <span className="d-flex mr-2">
                            <img src={iconPencil} />
                            View/Edit
                          </span>
                        </Link>

                        <Badge
                          color="danger"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setModal({
                              ...modal,
                              show: true,
                              type: "confirmation",
                              title: "Delete Student",
                              body: "Are you sure want to delete?",
                              onConfirm: () => {
                                deleteStudent(student.id);
                              },
                            });
                          }}
                        >
                          <span className="d-flex">
                            <img src={iconDelete} />
                            Delete
                          </span>
                        </Badge>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>

          <PaginationCmp
            current={currentPage}
            pages={Math.ceil(total / limit)}
            onPageClick={(pg) => {
              setCurrentPage(pg);
              setHistory({ ...queryParams, page: pg });
              loadDatas({ ...queryParams, page: pg });
            }}
          />
        </CardBody>
      </Card>
    </Container>
  );
};

export default withLayout(Student);
