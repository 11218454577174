import React, { useState, useEffect, useContext } from "react";
import { withLayout } from "../layout/Main";
import { Container, Card, CardBody, Modal } from "reactstrap";
import { Link, useParams, Redirect } from "react-router-dom";
import Axios from "axios";
import Config from "../config/config";
import FormStudent from "../components/FormStudent";
import Auth from "../helpers/Auth";
import AppContext from "../context/AppContext";
import { numberOfHoursTotals } from "../config/const";
import queryString from "query-string";

const EditStudent = (props) => {
  const [datas, setDatas] = useState([]);

  const { id } = useParams();

  const { modal, setModal } = useContext(AppContext);

  const [loading, setLoading] = useState(false);

  const [redirect, setRedirect] = useState(false);

  const [backUrl, setBackUrl] = useState("");

  useEffect(() => {
    loadData();

    const parsedQuery = queryString.parse(props.location.search);

    if (parsedQuery.back !== undefined) {
      setBackUrl(decodeURIComponent(parsedQuery.back));
    }
  }, []);

  const loadData = async () => {
    const authData = await Auth.authData();

    const results = await Axios.get(Config.baseUrl + "/student/" + id, {
      headers: {
        Authorization: "Bearer " + authData.token,
      },
    });

    const {
      first_name,
      last_name,
      gender_id,
      country_id,
      located_now_id,
      email,
      phone_code,
      phone,
      skype,
      course_id,
      payment_preference_id,
      referrer_id,
      referrer_desc,
      location_id,
      payment_request_sent,
      payment_received,
      note,
      newsletter,
      starting_date,
      starting_month,
      reason,
      preferred_time,
      number_of_hours_total,
      number_of_hours_per_day,
      tags,
      message,
    } = results.data;

    const formData = {
      firstName: first_name,
      lastName: last_name,
      country: country_id,
      located_now: located_now_id,
      email: email,
      phoneCode: phone_code,
      phone: phone,
      course: course_id,
      referrerDesc: referrer_desc,
      note,
      newsletter: newsletter === 1 ? true : false,
      reason,
      message,
    };

    if (gender_id) {
      formData.gender = gender_id.toString();
    }

    if (payment_preference_id) {
      formData.paymentPreference = payment_preference_id.toString();
    }

    if (referrer_id) {
      formData.referrer = referrer_id.toString();
    }

    if (payment_request_sent) {
      formData.paymentRequestSent = payment_request_sent.toString();
    }

    if (payment_received) {
      formData.paymentReceived = payment_received;
    }

    if (starting_date) {
      formData.startingDate = new Date(starting_date);
    }

    if (starting_month) {
      formData.startingMonth = starting_month;
    }

    if (skype) {
      formData.skype = skype;
    }

    if (location_id) {
      formData.location = location_id.toString();
    }

    if (preferred_time) {
      formData.preferredTime = preferred_time;
    }

    if (number_of_hours_total) {
      if (numberOfHoursTotals.includes(Number(number_of_hours_total))) {
        formData.numberOfHoursTotal = number_of_hours_total.toString();
      } else {
        formData.numberOfHoursTotal = "other";
        formData.numberOfHoursTotalOther = number_of_hours_total.toString();
      }
    }

    if (number_of_hours_per_day) {
      formData.numberOfHoursPerDay = number_of_hours_per_day;
    }

    if (tags) {
      formData.tags = tags.map((tag) => {
        return { id: tag, text: tag };
      });
    }

    setDatas(formData);
  };

  const onSave = async (data) => {
    const authData = await Auth.authData();

    setLoading(true);

    if (data.hasOwnProperty("startingDate")) {
      const newStartingDate =
        data.startingDate.getFullYear() +
        "-" +
        (data.startingDate.getMonth() + 1) +
        "-" +
        data.startingDate.getDate();

      data.startingDate = newStartingDate;
    }

    if (data.tags) {
      data.tags = data.tags.map((tag) => {
        return tag.text;
      });
    }

    if (
      data.hasOwnProperty("numberOfHoursTotal") &&
      data.numberOfHoursTotal === "other"
    ) {
      data.numberOfHoursTotal = data.numberOfHoursTotalOther;
    }

    delete data.numberOfHoursTotalOther;

    try {
      await Axios.put(Config.baseUrl + "/student/" + id, data, {
        headers: {
          Authorization: "Bearer " + authData.token,
        },
      });

      setLoading(false);
      setRedirect(true);

      setModal({
        ...modal,
        type: "alert",
        show: true,
        title: "Success!",
        body: "Data successfully updated!",
      });
    } catch {
      setLoading(false);
      console.log("Failed");
    }
  };

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: "/student",
          state: { from: props.location },
        }}
      />
    );
  }

  return (
    <Container>
      <Card>
        <CardBody>
          <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-4">
            <h2 className="page-title mt-0 mb-0">Edit Student</h2>{" "}
            <span className="ml-sm-3">
              [<Link to={backUrl ? backUrl : "/student"}>Back to the list</Link>
              ]
            </span>
          </div>
          <FormStudent datas={datas} onSave={onSave} loading={loading} />
        </CardBody>
      </Card>
    </Container>
  );
};

export default withLayout(EditStudent);
