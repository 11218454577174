import React, { useEffect, useState, useContext } from "react";
import { Chart } from "react-google-charts";
import Axios from "axios";
import DashboardFilter from "../DashboardFilter";
import Config from "../../config/config";
import CircleLoader from "../CircleLoader";
import Auth from "../../helpers/Auth";
import DashboardContext from "../../context/DashboardContext";
import stripHtml from "string-strip-html";

const DashWidgetCourse = () => {
  const { dispatchFilterActions, filterAll } = useContext(DashboardContext);

  const [datas, setDatas] = useState();

  const [filterTitle, setFilterTitle] = useState("All");

  const [formFilter, setFormFilter] = useState();

  const [applyToAll, setApplyToAll] = useState(false);

  const loadData = async (params, title = "All") => {
    const authData = await Auth.authData();

    const results = await Axios.get(Config.baseUrl + "/dashboard/course", {
      headers: {
        Authorization: "Bearer " + authData.token
      },
      params
    });

    const dataCourses = results.data;

    const dataLength = dataCourses.length;
    const dataTotal = dataCourses.reduce((total, item) => {
      return total + item.total;
    }, 0);

    const courses = [];
    for (let i = 0; i < dataLength; i++) {
      const dataCourse = dataCourses[i];
      const { course, total } = dataCourse;
      courses.push([course, (total / dataTotal) * 100]);
    }

    setDatas([["Course", title], ...courses]);
  };

  useEffect(() => {
    loadData();

    dispatchFilterActions({
      loadData,
      setFilterTitle,
      setFormFilter,
      setApplyToAll
    });
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between dashboard-widget mb-3">
        <div>
          <h3>Course Type</h3>
          <span className="filter-label">
            Filter:{" "}
            <span dangerouslySetInnerHTML={{ __html: filterTitle }}></span>
          </span>
        </div>

        <DashboardFilter
          applyToAll={applyToAll}
          formFilter={formFilter}
          onChange={filterData => {
            const { type, formData, title, applyToAll } = filterData;

            setApplyToAll(applyToAll);

            if (applyToAll) {
              filterAll(type, formData, title);
            } else {
              loadData(formData, stripHtml(title));
              setFilterTitle(title);
            }
          }}
        />
      </div>
      <Chart
        height={"400px"}
        chartType="PieChart"
        loader={<CircleLoader />}
        data={datas}
        options={{ chartArea: { width: 500, height: 360 } }}
      />
    </>
  );
};

export default DashWidgetCourse;
